import { PrivyProvider, useLogin, usePrivy } from '@privy-io/react-auth';
import { ZeroDevProvider } from '@zerodev/privy';
import { useQueryState } from 'nuqs';
import { useEffect } from 'react';
import { createConfig, useConfig, WagmiProvider } from 'wagmi';

import { getLoginDtoFromPrivyLogin } from '@endaoment-frontend/authentication';
import { config } from '@endaoment-frontend/config';
import { WALLET_CONNECT_PROJECT_ID } from '@endaoment-frontend/constants';
import { createTransportsForChains, getConnectors } from '@endaoment-frontend/multichain';
import type { LoginDTO } from '@endaoment-frontend/types';
import { Loader } from '@endaoment-frontend/ui/shared';

const SUBMIT_FORM_URL_QUERY_PARAM = 'submitFormUrl';

const InnerHomePage = ({ isPending, abortUrl }: { isPending: boolean; abortUrl: string }) => {
  const { login, isModalOpen } = usePrivy();

  useEffect(() => {
    if (isModalOpen || !isPending) return;
    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, isPending]);

  return (
    <>
      {/*  TODO (Ishaan): Make this look good ans show up in the login screen somehow */}
      <a href={abortUrl}>Abort</a>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100svh',
        }}>
        {isPending ? null : <Loader size='l' />}
      </div>
    </>
  );
};

const submitPrivyLoginAsFormSubmit = (submitFormUrl: string, body: LoginDTO) => {
  // Create a form to send the user to the auth server
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = submitFormUrl;
  form.style.display = 'none';

  // Add hidden inputs to the form for each field in the POST body
  (Object.keys(body) as Array<keyof typeof body>).forEach(key => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = body[key];
    form.appendChild(input);
  });

  // Append the form to the body and submit it
  document.body.appendChild(form);
  form.submit();
};
const PrivyAuthHandleProvider = () => {
  const wagmiConfig = useConfig();

  useLogin({
    onComplete: async (user, _isNewUser, _wasAlreadyAuthenticated, loginMethod) => {
      const urlParams = new URLSearchParams(window.location.search);
      const submitFormUrl = urlParams.get(SUBMIT_FORM_URL_QUERY_PARAM);
      if (!submitFormUrl) throw new Error(`Missing ${SUBMIT_FORM_URL_QUERY_PARAM} in the URL query params`);
      const loginDto = await getLoginDtoFromPrivyLogin({ wagmiConfig, user, loginMethod });
      submitPrivyLoginAsFormSubmit(submitFormUrl, loginDto);
    },
  });

  return null;
};

const connectors = getConnectors({
  appName: 'oauth.endaoment',
  appDescription: 'Endaoment',
  appUrl: 'https://oauth.endaoment.org',
  appLogo: 'https://storage.googleapis.com/endaoment-static/privy-logo-header.png',
});
const transports = createTransportsForChains(config.chains);
const wagmiConfig = createConfig({
  chains: config.chains,
  connectors,
  transports,
  batch: {
    multicall: true,
  },
  syncConnectedChain: true,

  // These settings are required for the Privy SDK
  ssr: true,
  multiInjectedProviderDiscovery: false,
});
const PrivyLoginPage = () => {
  const [submitFormUrl] = useQueryState(SUBMIT_FORM_URL_QUERY_PARAM);
  const [abortUrl] = useQueryState('abortUrl');
  const hasSubmitFormUrl = !!submitFormUrl;

  // TODO (Ishaan): Add an abort button to cancel the login process

  return (
    <ZeroDevProvider
      projectId={config.zeroDev.projectId}
      bundlerProvider='ALCHEMY'
      paymasterProvider='ALCHEMY'
      onlySendSponsoredTransaction>
      <PrivyProvider
        appId={config.privyAppId}
        config={{
          // Customize Privy's appearance in your app
          appearance: {
            logo: 'https://storage.googleapis.com/endaoment-static/privy-logo-header.png',
            theme: 'light',
            accentColor: '#676FFF',
            walletList: ['detected_wallets', 'metamask', 'coinbase_wallet', 'wallet_connect'],
            showWalletLoginFirst: false,
            landingHeader: '',
            loginMessage: 'Sign in to Endaoment',
            walletChainType: 'ethereum-only',
          },
          legal: {
            privacyPolicyUrl: 'https://docs.endaoment.org/governance/documentation/personal-data-usage-and-storage',
            termsAndConditionsUrl: 'https://docs.endaoment.org/governance/documentation/terms-and-conditions',
          },
          // Create embedded wallets for users who don't have a wallet
          embeddedWallets: {
            createOnLogin: 'users-without-wallets',
          },
          supportedChains: config.chains,
          walletConnectCloudProjectId: WALLET_CONNECT_PROJECT_ID,
        }}>
        <WagmiProvider config={wagmiConfig}>
          <PrivyAuthHandleProvider />
          <InnerHomePage isPending={hasSubmitFormUrl} abortUrl={abortUrl ?? ''} />
        </WagmiProvider>
      </PrivyProvider>
    </ZeroDevProvider>
  );
};

export default PrivyLoginPage;
